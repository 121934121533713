const getRandomNum = (min, max) => {
  let random = (Math.random()*(max-min))+min;
  return Math.round(random);
};

const getHistoryFromLocalStorage = type => {
  const historys = window.localStorage.getItem(type+'-result');
  if (!historys) {
    return [];
  }
  if (historys.indexOf('**')) {
    return historys.split('**');
  } else {
    return [historys];
  }
};

const setHistoryToLocalStorage = (type, result) => {
  if (Array.isArray(result)) {
    window.localStorage.setItem(type+'-result', result.join('**'));
  } else {
    window.localStorage.setItem(type+'-result', result);
  }
};

const replaceText = text => {
  if (text && text.indexOf('::::') > 0) {
    const texts = text.split('::::');
    const all = texts.map(tx => <div>{tx}</div>);
    return <div>{all}</div>
  }
  return text;
};

export {
  getRandomNum,

  getHistoryFromLocalStorage,
  setHistoryToLocalStorage,

  replaceText
};