import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Container from 'react-bootstrap/Container'

import Home from './Home';
import Selections from './component/Selections';

import { options } from './util/data';

import 'bootstrap/dist/css/bootstrap.min.css';

const AppRouter = props => {
  return (
    <Container>
      <Router>
        <div>
          <Route key="00" exact path="/" component={Home} />
          {
            options.map((opt, i) => {
              return <Route key={i} exact path={"/"+opt.code} render={() => <Selections type={opt.code} />} />
            })
          }
        </div>
      </Router>
    </Container>
  )
}

export default AppRouter;


// <Route exact path="/" component={Home} />
// <Route exact path="/all" component={ThousandWordsAll} />
//   <Route exact path="/test" component={ThousandWordsTest} />
//   <Route exact path="/home" component={ThousandWords} />