import React, { useState, useEffect } from 'react';

import { getHistoryFromLocalStorage, setHistoryToLocalStorage, replaceText } from '../util/util';
import { options } from '../util/data';

const HistoryPage = props => {
  const [ adults, setAdults ] = useState([]);
  const [ kids, setKids ] = useState([]);
  const [ englishs, setEnglishs ] = useState([]);
  
  useEffect(() => {
    options.forEach((option => {
      const history = getHistoryFromLocalStorage(option.code);
      setData(option.code, history);
    }));
  }, []);

  const setData = (option, data) => {
    switch(option) {
      case 'adult': {
        setAdults(data);
        break;
      }
      case 'kids': {
        setKids(data);
        break;
      }
      case 'english': {
        setEnglishs(data);
        break;
      }
      default: {
        setAdults(data);
      }
    }
  };

  const getData = option => {
    if (!option) {
      return [];
    }
    switch(option) {
      case 'adult': {

        return adults ? adults : [];
      }
      case 'kids': {
        return kids ? kids : [];
      }
      case 'english': {
        return englishs ? englishs : [];
      }
      default: {
        return adults ? adults : [];
      }
    }
  };
  
  const styles = {
    historyPage: {
      overflowY: 'scroll',
      width: '100%'
    },
    title: {
      color: '#ff0000',
      fontSize: '1.5em',
      textAlign: 'center',
      marginTop: 40
    },
    close: {
      position: 'fixed',
      top: 10,
      right: 10,
      fontSize: '2.0em',
      color: 'red'
    },
    resultSection: {
      marginBottom: 40
    }
  };

  const removeResult = (i, type) => {
    let history = getHistoryFromLocalStorage(type);
    const newResult = [...history];
    newResult.splice(i, 1);
    setHistoryToLocalStorage(type, newResult);

    setData(type, newResult);
  };

  return (
    <div style={styles.historyPage}>
      {/* <div style={styles.close} onClick={props.closeShowHistory}>
        X
      </div> */}
      <div>
        <div className="history-title">我的籤 (My Drawings)</div>
        {
          options.map(theOption => {
            let option = theOption.code;
            const drawings = getData(option);

            if (drawings.length < 1) {
              return null;
            } else {
              return (
                <div style={styles.resultSection}>
                  <h6 className="category-title">{theOption.name}</h6>
                  <div>
                    {
                      getData(option).map((result, i) => {
                        return (
                          <div key={i} className="history-card" key={option+i}>
                            <div>{replaceText(result)}</div>
                            <a className="delete-btn" onClick={removeResult.bind(this, i, option)}>x</a>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            }

          })
        }
      </div>
    </div>
  )
};

export default HistoryPage;