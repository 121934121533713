import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import ButtonPage from './ButtonPage';
import ShowTicket from './ShowTicket';
import HistoryPage from './HistoryPage';

import { adult, kids, english } from '../util/data';
import '../style/spinningCircle.css';

const Selections = props => {
  const [ type, setType ] = useState(props.type);
  const [ selections, setSelections ] = useState([]);
  const [ buttonSize, setButtonSize ] = useState(0);
  const [ pressed, setPressed ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ fu, setFu ] = useState(false);
  const [ showHistory, setShowHistory ] = useState(false);

  let history = useHistory();

  useEffect(() => {
    updateSelections();
  }, [type]);

  useEffect(() => {
    const element = document.getElementById('theButton');
    const height = element ? element.clientHeight : 0;
    const width = element ? element.clientWidth : 0;

    let size = height < width ? height : width;
    setButtonSize(size/2);
  }, []);

  const updateSelections = _ => {
    switch(type) {
      case 'adult': {
        setSelections(adult);
        break;
      }
      case 'kids': {
        setSelections(kids);
        break;
      }
      case 'english': {
        setSelections(english);
        break;
      }
      default: {
        setSelections(adult);
      }
    }
  };

  const styles = {
    clickButton: {
      height: '70vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    againButton: {
      padding: '20px 50px',
      borderRadius: 5,
      marginTop: 30,
      marginBottom: 50,
      textAlign: 'center'
    }
  };

  const handleClick = event => {
    // setPressed(true);
    setLoading(true);
    setTimeout(_ => {
      setLoading(false);
      setFu(true);
    }, 2000);

    setTimeout(_ => {
      setFu(false);
      setPressed(true);
    }, 3000);
  };

  const clearStorage = _ => {
    // window.localStorage.removeItem(type+'-result');
    setPressed(false);
    history.push('/');
  };

  const closeShowHistory = _ => {
    setShowHistory(false);
    setPressed(false);
  };

  const openShowHistory = _ => {
    setShowHistory(true);
  };

  const drawAgain = _ => {
    if (type === 'english') {
      return "Draw Again!";
    } else {
      return "再抽一次";
    }
  };

  const myDrawings = _ => {
    if (type === 'english') {
      return "My Drawings";
    } else {
      return "我的籤";
    }
  };

  const backToHome = _ => {
    if (type === 'english') {
      return "Home";
    } else {
      return "回上頁";
    }
  };

  return (
    <div style={styles.page}>
      <Row noGutters className="header">
        <Col xs={{ span: 12}} md={{span: 8}} className="logo-wrap">
          <div><img className="logo" src="\img\chung-logo-shadow.png" alt="logo" /></div>
          <div><img className="logo-heading" src="\img\heading-title.png" alt="logo heading" /></div>
        </Col>
        <Col xs={{ span: 12}} md={{span: 4}} className="year-print">
          <img src="\img\year2022.png" className="year-pring-img" alt="year 2022" />
          <div className="top-nav-warp">
            <div className="btn-history">
              <img width="25" height="25" src="/img/history-icon.png" />
              <input type="button" value={myDrawings()} onClick={openShowHistory.bind(this)}/>
            </div>
            <div className="btn-back">
              <img width="25" height="25" src="/img/back-icon.png" />
              <Link to="/" className="top-nav-link back-arrow">{ backToHome() }</Link></div>
          </div>
        </Col>
      </Row>
      {
        showHistory ?
          <HistoryPage type={type} closeShowHistory={closeShowHistory} /> : (
          <div>
            {
              pressed ? (
                <div className="card-result">
                  <ShowTicket type={type}/>
                  <Row noGutters>
                    <Col xs={{ span: 12}} md={{span: 6}}  lg={{span: 6}} >
                      <a className="button-large draw-again-btn" onClick={clearStorage}>{drawAgain()}</a>
                    </Col>
                  </Row>
                </div>
              ) : ( 
                fu ? (
                  <div className="flip" id="theButton" style={styles.clickButton}>
                    <ButtonPage type={type} text="福" handleClick={() => {}} buttonSize={buttonSize}/>
                  </div>
                ) : (
                  <div className={loading ? 'spinCircle flip' : ''} id="theButton" style={styles.clickButton}>
                    <ButtonPage type={type} text={ loading ? '福' : null } handleClick={handleClick} buttonSize={buttonSize}/>
                  </div>
                )
              )
            }
            
          </div>
        )
      }

    </div>
  );
};

export default Selections;