import React, { useState } from 'react';

const ButtonPage = props => {
  const [ type, setType ] = useState(props.type);
  
  const styles = {
    clickButton: {
      width: props.buttonSize,
      height: props.buttonSize,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: '2.0em'
    }
  };

  const theText = _ => {
    if (type === 'english') {
      return "Draw";
    } else {
      return "抽籤";
    }
  }

  return (
    <div className="buttonPage" onClick={props.handleClick} style={{...styles.clickButton}}>
      {
        props.text ? props.text : theText()
      }
    </div>
  )
};

export default ButtonPage;