import React from 'react';
import { Link }  from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { options } from './util/data';

import './style/home.css';

const Home = props => {
  const styles = {
    frame: {
      backgroundImage: "url(" + "img/frame_short.png" + ")"
    }
  };

  return (
    <div>
      <Row noGutters className="header">
        <Col xs={{ span: 12}} md={{span: 8}} className="logo-wrap">
          <div><img className="logo" src="\img\chung-logo-shadow.png" alt="logo" /></div>
          <div><img className="logo-heading" src="\img\heading-title.png" alt="logo heading" /></div>
        </Col>
        <Col xs={{ span: 12}} md={{span: 4}} className="year-print d-none d-md-block">
          <img src="\img\year2022.png" alt="year 2022" />
        </Col>
      </Row>
      <Row noGutters className="drawing-content">
        <Col xs={{ span: 12, order: 1 }} lg={{span: 6, order: 1}} style={styles.frame} className="drawing-frame">
          <p className="card-description">"心誠則靈"虔誠地在心中默念您的姓名, 誠心祈求仙佛給予詩籤</p>
          <p className="card-description-eng">Please silently recite your name and sincerely pray for Buddha to grant us a fortune.</p>
          <hr className="card-divider" />
          <div>
            {
              options.map((opt, i) => {
                return (
                  <Link key={i} to={"/"+opt.code} className="button-large">
                    {opt.name}
                  </Link>
                )
              })
            }
          </div>
        </Col>
        <Col xs={{ span: 12, order: 2 }} lg={{span: 6, order: 2 }} className="d-none d-lg-block d-xl-block">
          <div className="drawing-wrap">
            <img className="drawing-img" src="\img\drawing-card.png" alt="Drawing" />
          </div>
        </Col>

      </Row>
    </div>
  )
};

export default Home;