import React, { useState, useEffect } from 'react';
import Zoom from 'react-reveal/Zoom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import { adult, kids, english } from '../util/data';
import { getHistoryFromLocalStorage,
  setHistoryToLocalStorage, replaceText } from '../util/util';


const ShowTicket = props => {
  const [ text, setText ] = useState(null);
  const [ result, setResult ] = useState(null);
  let resultTitleUrl = "result-title.png";

  useEffect(() => {
    if (text) {
      const len = text.length;
      const rand = Math.floor(Math.random() * len);
      const newResult = text[rand];

      setResult(newResult);

      let oldResult = getHistoryFromLocalStorage(props.type);
      if (!oldResult) {
        oldResult = [];
      }
      if (!Array.isArray(oldResult)) {
        oldResult = [];
      }
      oldResult.push(newResult);

      setHistoryToLocalStorage(props.type, oldResult);
    }
  }, [text]);

  useEffect(() => {
      switch (props.type) {
        case 'adult':
        {
          setText(adult);
          break;
        }
        case 'kids':
        {
          setText(kids);
          break;
        }
        case 'english':
        {
          setText(english);
          break;
        }
        default:
        {
          setText(adult);
        }
      }
  }, [props.type]);

  if (props.type=="english") resultTitleUrl = "result-title-eng.png";

  const styles = {
    blue: {
      backgroundColor: 'blue',
      color: 'yellow'
    },
    gradient: {
      background: 'linear-gradient(90deg, rgba(251,5,5,1) 0%, rgba(255,147,0,1) 30%, rgba(255,246,0,1) 100%)',
      color: '#0000ff'
    },
    frame: {
      backgroundImage: "url(" + "img/frame_short.png" + ")"
    }
  };

  return (
    <Zoom bottom>
      <Row noGutters className="drawing-result">
        <Col xs={{ span: 12}} md={{span: 6}} lg={{span: 6}} style={styles.frame} className="drawing-frame">
          <img src={'/img/'+resultTitleUrl} className="result-title-img" alt="result title" />
          <div className="result-card">{replaceText(result)}</div>
          <img src="\img\best-draw.png" className="best-draw-img" alt="Best Draw" />
          <img src="\img\best-draw-card.png" className="best-draw-card" alt="Best Draw" />
        </Col>
        <Col xs={{ span: 12}} md={{span: 6}} lg={{span: 6}} className="d-none d-md-block d-lg-block d-xl-block">
          <div className="drawing-wrap">
            <img className="drawed-img" src="\img\drawing-card-drawed.png" alt="Drawing" />
          </div>
        </Col>
      </Row>
    </Zoom>
  )
};

export default ShowTicket;