const options = [
  {
    code: 'adult',
    name: '成人'
  },
  {
    code: 'kids',
    name: '小朋友'
  },
  {
    code: 'english',
    name: 'English'
  }
];

const adult = [
  "修道就是先改變自己，而且不要先入為主。要先觀察、先體悟、還要先參與，你才可以下定論。",
  "功在暗處得，莫在表面圖虛名。守住自己的愿，多做少說；注意自己的身體，莫要逞強，珍惜自己的生命。",
  "以德報怨，莫枉費承受的恩德，既然承受的多，就要了愿的多，不要去想任何麻煩的問題，心靜一切空，空了有何煩惱？",
  "人的生命是有極限的，可是只要發揮你生命之光就會有無窮能量。所以，徒兒要好好的發揮你自己，以你的才華去幫助別人，奉獻給社會。",
  "只要你盡心盡力，上天自然會撥轉，你不求也會撥轉。如果你不腳踏實地，沒有真誠，就算你求了也沒用，上天是公平的。",
  "何謂劫？在於看你的心怎麼去過這個劫，你肯你就過了，你不肯你還是留在原地踏步，永遠在這個劫數之中，沒有辦法超脫。",
  "做什麼事情都一樣，不要一直等明天，要把握今天、把握現在、把握當下，這才是真的，才是永恆。",
  "選擇適合自己的路。每個人皆有本身具足的能力，既是本身所有，就不用再去找，更不要掉進漩渦，因為本來就沒有漩渦，又何須跳出。",
  "修練功夫，既麻煩又苦，但只要有心，只要有誠意，都可以突破的。事在人為，你跟上天要什麼，上天就給你什麼。堅持自己的信念，不要輕易改變。",
  "什麼是無為而為呢？當在意愈多，得到便愈少，執著越多就越痛苦，煩惱也就越多。",
  "道心是不著人情的，是不為事相的變化而有所動搖的！身擔代天宣化的使命，來辦末後一場，所以道心一定要堅定。",
  "不管你身擔何種角色，都有你的使命。為師希望徒兒都要以智慧來轉法輪，將你的小圈圈匯聚成大圈圈，讓法輪常轉，這樣才能擁有一個快樂的人生。",
  "每個人都盡忠職守，把自己的本分做好，就有道，道法自然，才不會受到束縛。",
  "時時記得自己的諾言，自己所發的心、所立的志向，對為師來說並沒什麼好處，那是你自己的所得啊！",
  "你做多少好事，上天絕不會抹滅的，相對的，你若做了哪些不好的事，也難逃天眼。徒兒，走出來服務眾生，讓你的人生變得更有意義、更有目標。",
  "學道要先從心做起，所謂相由心生，佛性顯現時，不會有好壞善惡，更不會被外境所影響。",
  "活在這個人世間處處皆是學問，處處皆有道。隨時隨地多去觀察，不是來佛堂才有道，才能行道，在各處各地，生活到處都能行道。",
  "今天學到多少，就要表現多少，這個才叫做道！今天學的，等過幾天再去做，那就不是道了，所以當下即是道。懂得面對自己的缺點，懂得面對自己的錯誤，這才是道。",
  "低心下氣你才能學習到更多的東西，知道嗎？先把自己裡面的所有的東西拋棄掉，才能容納更多的智慧。",
  "修道人最重要的德行是：謙讓、虔誠、敦厚、力行、孝順、尊師、勤儉。",
  "心不可以太閒，太閒之後就會作怪，所以常常胡思亂想，想靜下專心時偏偏靜不下來，因為平常沒有照顧你的心，就沒有辦法收放自如。所以要反觀、反省自己的心。",
  "當你『心無饜足，惟德多求』時，便會遭受無意識束縛之苦，人比人會氣死人，人看人只會苦死人啊！既然修道了，就要曉得『菩薩不爾，常念知足』啊！",
  "天地已經給了你一切，所以要懂的知足與珍惜。事事要能知足常樂，常懷感恩，你只要有一顆善念，仙佛處處都會助你。",
  "人生的福境禍區，皆念想造成。要想知道，相由心生，相隨心滅。心，是萬境之容器，但看你要容何物？你只有先善養其心，時時發正念，才不被怨、恨、惱、怒、煩佔據你的心。",
  "錢，是人見人愛的東西，可讓人爬的很高，也可讓人跌得很重，經常左右著人的情緒，令人喜怒哀樂反覆無常；所以有智慧的人，錢只要夠用就好，知足的人才會常樂。",
  "行深的功夫，就像如何取得乾淨精純的？要靜上，沉澱再過濾。一次又一次的懺悔與感恩，就像水在過濾一樣，直到習氣毛病在你的八識田中除去止。",
  "徒兒是發了菩薩愿的，行事待人要誠懇，用天心來行事辦事，修持功夫需以德量來奠基，海涵一切人事物，凡事以和為貴，有雅量、有氣節，以德化人，以理服人，常存感恩，自然道氣充水沛。",
  "修道人在心情不好的時候，碰到父母，臉上也不要表露不悅，要不然父母看了會難過。所以為師要提醒，不要忘記這一點。",
  "把握有限的時光，感恩現有的因緣，歡歡喜喜的侍奉父母，快快樂樂的扶持另一半，高高興興的友愛手足，開開心心的教育下一代。",
  "留錢給子孫，子孫不一定能守；留書給子孫，子孫不一定能讀，唯有留德行給子孫，子孫才能世世代代昌盛。",
  "什麼叫「滿願如意」？就是圓滿自己的願望，只要做到「孝順」，就能滿願如意。",
  "有健康的身體，還需要有健康的心，才是正確的養生之道。雖然每個人的養生之道不一樣，每一個人的心也不一樣，但是『自性』不會不一樣。",
  "天氣冷了，要多添加衣服，雖然肉體是假的，但是沒有這個假，還無法修真呢！",
  "現代人生活十大守則：行動快一點，嘴巴甜一點，肚量大一點，做事多一點，理由少一點，說話輕一點，腦筋活一點，效率高一點，微笑多一點，脾氣少一點。",
  "找出自身的盲點，看出自己的弱點，改變自身的缺點，發揮自我的優點。",
  "天地不可一日無和氣，人心不可一日無喜神，修道不可一日不殷勤，修心不可一日不淨心。",
  "平常要以誰為師？沒看到為師，也不知道為師在哪裡，你要以誰為師？要以天地良心為師，以你的良心來做事、講話，差錯就少。",
  "修得好也要講得好，口、心、行要合一，這樣才像是修道人。修道最重要是要明理。",
  "把自己的人修正一點，你遇到濁世時就不會亂。這顆心要平和才能平安，遇到危險才能從容應付。",
  "你的心若不計較，對任何事都比較看得開。無煩無惱、快快樂樂才會平平安安。",
  "怎麼樣才能夠平安？往外追尋，還是向內求？首先要從你的心開始平起，心平的話，你的身自然安，你沒私慾自然就心安。",
  "眾生的理想的不盡相同，如果連自己都不了解，如何去了解別人、關心別人？",
  "內在涵養功夫如何？就看你平常一舉一動，這全在隱微之間就可看的一清二楚。要惻隱為懷，見眾生之苦如己苦，見眾生之溺猶如己溺，見眾生之悲視同己悲，存佛心時則與眾生一體。",
  "仙佛菩薩是由人來修的，這個初發心能保持不變就能成仙作佛，如果此初發心變了、退志了，你自然無法達成你的心愿。",
  "如果心裏感動就要馬上去行動，動什麼啊？是動心呢？還是動作？如果是動作，就得是利益眾生、為國家、為社會所盡的一點心而付出的行動。",
  "修道辦道，家庭一定要和樂，一定要讓家裏的可以讓解、了解你為什麼這樣做。",
  "佈施的好處就是化煩惱為菩提。因為在慈悲的當下，便是天心的作用，沒有一點業識的染著與觸動，所以才說：『常懷佈施心，斷卻貪、嗔、癡』，便是這個道理。因為給予而富足，這是一種美妙的感受，徒要知道，佈施的心沒有任何雜質才是真擁有啊！",
  "講財施，你能夠對錢財看的輕淡，夠用就好。把它拿出來做道場事業、慈善事業，開荒辦道，救眾生出苦海淵，那不是很好嗎？但是，你捨得嗎？",
  "法施，每個人都會講道。天沒有道，沒辦法清澈；地沒有道，沒辦法負載；人沒有道，沒辦法靈光。道，是萬靈的真主宰。過去，道降君王，現在，道降百姓。所以要有感恩的心，用實際行動去度化眾生，這才是最大的法施。",
  "生命，要有一種自我要求的堅毅精神，不讓自己有一絲的橫越不軌，這就是斷除惡業的刀斧。如此奉行不渝，必能圓滿人生。",
  "道是無形無相的，要修無形無相的很難，修有形有相的比較簡單。就像看得到的佈施比較容易做，看不到的心念卻不容易修，所以才說要以無為之心來了透有所作為的心。",
  "要把自律的心帶回家庭生活裏，真正做到應盡的責任，不管在聖事、凡事都要自我要求，才能夠合乎中道。在培德的過程中，一定要戒除自滿和傲惰的心，要以謙和的風度和慎行的態度來要求自己、歷練自己，因為要進步，就要不斷反求諸己，持戒，守恆，才能邁向康莊大道。",
  "什麼是無畏施？去除人相、我相、眾生相、壽者相，便是無畏施。比如說，你能察言觀色，伺機而行。哪裡有紙屑，及時撿起；哪裡髒了，自動擦拭；哪個人身體不好了，趕快主動關懷；哪個人沒吃飯，便主動下碗麵去給他吃，這就是無畏施。",
  "持戒是最好的保護傘，也是遠離諸惡的門戶。用戒律來糾正惡念惡習，用戒律來彰顯正心正行，不要怕戒規的多少，能持戒的人才有機會踏上聖賢路。",
  "一念差池作惡多端，一念心善絡身奉行。所以，掌握這一念很重要，徒的心猿意馬外放，要如何收束呢？就是要持戒。先學不越矩半分，之後才能來去從容。",
  "不要認為佛規是一種拘束，因為人有很多無明的盲點，所以才借著外在的要求來警惕自己的行為，從遵守到自然，這是一條必經的路。徒啊，改毛病去脾氣不是口訣，是行動的指標，所以要持戒守恆。",
  "一個懂得持戒的人必有聖賢之格，為什麼這麼說呢？考查古聖先賢，都是先從自律著手，之後才談修行。要知道，越懂得自律的人，越能得到法喜與自在。因為這些不再是所謂的束縳，而是生命的自然流露。",
  "要常常結隨喜的緣，這一輩子多幫忙別人，貴人才會多，路走起來才會更平順，不要到處看那個不好、這個也不喜歡，越修道要越有德，品性好、人格好、肚量大又從善如流，隨時接受別人給的建議，眾生哪有不接近你的道理。",
  "不管有什麼毀言謗語，或聖事凡事，或身體上的不順，都要虛心接受，才有機會消解障礙，更不可以害怕任何不順遂的境遇，只要不離道場，都有機會立下寸功。",
  "人的心念如果時時都存著善念，幫助別人的心、救渡眾生的心，這樣子的心就叫作菩薩的心。",
  "人的心念會影響你的磁場，如果你常常想說你很命苦，原本好運要來了，都被你說得好運又走了，所以不能夠常常說自己命不好，要說你命很好。",
  "人最怕自以為自己了不起、自己才能很好了，這很危險，修道要不斷謙虛自己，眼中要常常看別人的好，年輕人學習事情很快，要學得專，不要學得一知半解，自以為了不起都明白了，其實自己什麼都不會，這樣不行。",
  "在佛堂學習道義，出了社會處事更要有道義之心，不可以做違背良心的事，修道要修心養性、要培德，與人為善、處事以和為貴，才能夠達到圓融，這些是必須要學習的。",
  "修道不斷的精進，不斷再進步自己的心性，所以自己無時無刻更要戰戰兢兢，修道不是功就是過，是功多還是過多呢？過多功少！要更加油，再精進，要把脾氣化為和氣，要把賭氣換作志氣，要去除傲氣展現骨氣，更要掃除邪氣秉正氣，修道就是這麼簡單。",
  "知行合一乃是做人最基本的道德，知行合一也是一種傳統的美德，一個人懂得知行合一，能夠說實話、辦實事，那麼別人很自然的就會對他信任與敬重。",
  "道不是在佛經裡找，道要在日常當中行出，感覺悟道，那就是你的，修行才能快樂，一日修來一日功，一日不修一日空。",
  "『覺』就是不迷的心，常常清醒的心，那就是覺，覺就是不迷，常快快樂樂的。如果常常覺性，成道有餘。為什麼不成道？因為眾生的覺性，只保持三分鐘的熱度，一經人事的歷練，就黑暗了。",
  "有智慧的人能夠知道自己要抉擇的是什麼；而聰明的人，都希望圖個僥倖，想一想，人空空來，將來也會空空的走。",
  "登上九品蓮台是沒有誰能幫誰的，自己的道要自己修，自己的看毛病要自己改，不能改毛病去脾氣，自性就不能圓滿，沒有真修實學，道就不能成，誰都幫不了誰。",
  "懂得上進的人就會不斷自我教育積極主動的學習與參辦。一個沒有進取心的人，生命就顯得無力，因此說『君子上達』。一個君子，他懂得自我精進，不斷往上提升。",
  "你自身做的好，人家就佩服你，德行足了，妙智慧就開啟，毛病脾氣一日一日的減少，這才叫做有進步。",
  "人要學而不厭，才可達到真正的智。不但要活到老，學到老，做到老，還要教不倦。因此，度人、成全人這件事，要有不倦怠的熱忱，才能激發出內在真正的仁德，對眾生永遠有一種捨與慈愛的心。",
  "徒啊，待人放的寛，生活不憂煩，為對方多想點。在人與人的相處中，若太計較對錯，彼此會很辛苦，也不會有好處。因此，同修在一起學習，就是要借事來成長，首先調整自己的心態，而不是等待別人來配合你，如此才能一起共事。",
  "慈悲喜捨，包括三施並行：財施、法施、無畏施。::::財施—令得資生之樂。::::法施—令成智慧之益。::::無畏施—令離苦惱之境。",
  "財施有四種好處：（1）消孽；（2）行善；（3）濟世；（4）積福。所謂「醫院」是消業障的活刑場—人間「地獄」啟示錄。「佛堂」是消業障的好道場—人間「天堂」啟示錄。",
  "財不動便腐化，濟人便善化，多財便惡化。所以賢而有財，則損志；愚而多財，則造過；富而能施，福也；貧而能施，貴也；儉而能施，仁也。",
  "無定智則不能悟道。無定見則不能修進。無定心則不能辦道。無定力則不能守道。無定性則不能成道。",
  "慾是害身之禍根。牢心之囹圄，損性之斧柯，故「人慾淨盡，天理流行」。 人能常清靜。天理悉皆歸。 ",
  "何謂清？何謂靜？::::清—洗心滌慮是清、無脾氣毛病是清、頭腦靈敏是清、胸無掛慮是清。::::靜—凡是放得下是靜、憤怒不做是靜、置是非、毀譽於度外是靜、撇開家累是靜、不著拘礙是靜、不生煩惱靈明是靜，心無貪念妄想是靜。",
  "水不拒各種的污穢，就像君子的教化與包容，能夠洗淨不潔的東西。徒兒若要做苦海與極樂世界的一座橋樑，去接引一切有緣眾生。人與人之間的相處也是一樣，要時時刻刻搭起良好人際關係的橋樑，常說好話，互相忍讓，才能廣結善緣。",
  "我時時自我要求，邪淫不生於心，是非不出於口。念念清靜純善，言言誠懇無欺。多為別人付出，福慧就在其中增添。少為自己爭奪，牽纏定於此處消減。熱心助助人不嫌棄貧賤，仔細觀照塵世中的缺欠。安分守己不強求富貴，用心體驗生命的俱足。",
  "不念舊惡知足感恩，寧靜的心靈散發溫馨的氣。不但做自己的貴人，更做父母、親戚、朋友，以及眾生等的貴人。為自己造福，也造福人群，造福這個世界。",
  "世人都好求福消禍、驅邪避凶，但不知一切的吉凶禍福、成敗得失皆來自於自己平常所做所為所致，想要改運的話就多行功立德。",
  "年紀輕輕就來修道，可以免去許多不必要的折磨與苦痛，那麼你就由日日是生日、日日是死日做起；也可以說：前一分鐘是死、後一分鐘是生，把『現在』當做是最主要下工夫的時候。",
  "煩惱如苗，天天都有增長的時候；妄念如海水，時時都有起伏的時候，所以人心是最難降伏的。要斷除煩惱；洗心滌慮，就要多聽道理。要有克己；反省的功夫。",
  "人生短短數十年，悲哀是一天、歡喜也是一天、迷昧紅塵是一天、修道了愿也是一天，那麼你要哪一天？(修道了愿)回無極理天啊？",
  "你要知道修道不是在逞乖弄巧，而是要敦其樸實、藏其智巧。韜光養晦、以真心來修道。有這『般若之智』才可以讓你趨向菩提之道。",
  "求道以後還要靠「修內德」來消除自身的不良業識，靠「行外功」來消除累世業債。所以「修內德」及「行外功」是行，「行」代表執行、實踐、實行，也就是一種漸修的法門、漸悟的法門。",
  "身為一個人，人與人相處，互相搭配了愿之時，必定會有人事糾紛、有口角爭執、有想法不合出現，這就是『在行外功當中修內德』的的功夫。",
  "你在行外功，也就是在財施、法施、無畏施、各種相助道務的事情當中，必需修內德、煉內德，內德與外功不是只單做一項就能圓滿。所以在互相搭配了愿中，必須磨練、必須收斂自己的壞脾氣、錯誤想法。",
  "治心功夫在定靜，治人功夫在誠信，治事功夫在精規，治身功夫在勤養。 如果能領悟這些道理，自然會把心定靜下來。",
  "如果只行外功而不修內德，就算是了愿一輩子、在道場幫助道務一輩子，心境都很難提昇。如果只行外功而不修內德，很容易造下各種因果業債，尤其是口過。而只修內德不行外功之，日久會容易陷入自私、高傲、不管他人的心情、心態，而這種心態其實就不是內德深厚的涵養，所以到最後連深厚的內德也會失去。",
  "樂莫樂於為善，吉莫吉於知足。故積善之家必有餘慶，積不善之家必有餘殃。所謂小善小惡眼前有報應，大善大惡未來報應。 人而為善，福雖未至，禍已遠矣。人而為惡，禍雖未至，福已遠矣。",
  "當你為眾生、為他人付出多少，他人未來必定會回饋於你，未來你在無形界當中相見時，會更加珍惜、更加感恩對方的付出，也會更力珍惜彼此的善緣、道緣。",
  "修道修心，心最難修！有些人認為修道就是來佛堂聽道理、做些事、幫幫忙。請問，這就是修道了嗎？這只是你應該做的事而已，不算是修道。",
  "要愛惜自己的名譽，清清白白，不要隨著社會上不好的習俗把自己迷失了，要自重自愛，修道就是保持這顆赤子之心、這顆純潔的心，不然就枉費了。",
  "有很多事並不是走到了盡頭，沒有轉彎的餘地，而是到該轉彎的時候了，轉彎，是不再一味使用過去的經驗模式，而是先忍一下，冷靜下來，再換個方式去為他人著想，待心氣平穩之後, 便會看到轉機。",
  "常常發脾氣的人常與災厄為伍，因為他常有惡氣竄流，祥瑞之神就難登門造訪，才會苦厄不斷，因此，要常做『存好心、說好話、做好事』這三件事。",
  "修道要先修好口德，一句話講對了，就成全人；一句話講錯了，就考倒人。修道其實不難，只在能否守住這清淨念？清淨念不見了，容易也會變得困難。要掌握住當前這一念，常保這一念的清淨，那麼修道就容易了。",
  "修道是要在每個做事的「當下」，不管別人對你嘮叨、別人對你百般挑剔、別人對你阿諛奉承、別人對你恭維讚嘆，你都不動心。「念念都清靜」，這才算是真功夫，才算有道。"
];

const kids = [
  "惜衣有衣，愛飯有飯。",
  "擺脫了壞夥伴的人，才可以談前途。",
  "力求悔改勇於認錯。",
  "體貼父母心意，接受父母教訓。",
  "做事切實誠懇待人。",
  "我不會無故傷害生命。",
  "愛護幼小兒童，待人和藹誠懇。",
  "幫關懷父母健康，幫助料理家事。",
  "態度謙恭，語言溫和。",
  "我會原諒他人過失。",
  "吃穿節儉，珍惜勞動成果，才能有吃有穿。",
  "我對師長、同學要有禮貌。",
  "早睡早起遵守作息時間、省吃儉用不浪費金錢。",
  "隨時隨地幫助他人、助人為善最快樂。",
  "實行節約，救助貧困、為人做事，視同為己。",
  "我愛護年幼的同學；也能讚美別人的優點。",
  "我早睡早起節省用電、我不吝嗇不浪費。",
  "我不隨便買零食吃、不亂買不需要的東西。",
  "要聽父母的話、幫助父母做我能做的事。",
  "我要幫助窮苦的同學、遇到有困難的人盡量幫助。",
  "不說粗野的話、不在背地裡做壞事。",
  "不貪圖不應得的東西，不誇耀自己的長處。",
  "做事負責任勞任怨、盡自己的能力，做好分配的工作。",
  "要時時面帶笑容、同情和愛護貧苦的人。",
  "世上有兩件事不能等：一孝順，二行善。",
  "讓父母安心的孩子才有福。",
  "對父母要知恩、感恩、報恩。",
  "小事不做，大事難成。",
  "做好事不能少我一人，做壞事不能多我一人。",
  "再不愛吃的東西也要吃完，再不愛聽的話也要聽完。",
  "衣冠不求華美，惟須整潔。",
  "一個手腳健全卻不肯做事的人，就等於沒有手腳的人。",
  "父母呼，應勿緩；父母命，行勿懶；父母教，須敬聽；父母責，須順承。",
  "為人子不晚起，衣被自己整理，晨昏必定省。",
  "長者與物，須兩手奉接。",
  "長者立不可坐，長者立不可坐。"
];

const english = [
  "By cultivating Dao you need to eliminate the bad thoughts one by one and keep the good thoughts.",
  "Understand the meaning of your life and start from making the best of your life. When you release yourself, willing to play your part and shoulder the mission given by God, true happiness will follow you.",
  "Bring people to receive Tao. To change your current situation, you need to learn Tao and cultivate Tao. Heaven wants to see your true heart and will arrange good affinities for you.",
  "If you cannot comfort or ease your troubled hearts, how do you pray to Buddha to give you a smooth path without any obstructions? First, you have to settle our hearts, and then we can receive Buddha’s help.",
  "People in higher positions must set good examples for others to follow. This is a good way to discover our true selves and improve ourselves accordingly.",
  "Buddhas are merciful; they are never willing to see people suffering. Understand your life, cherish your affinity, stay in good shape. So many people are awaiting you to bring Tao to them.",
  "You need to transform your meretricious temperament, bad habits and bad tempers so that your noble temperament will show.",
  "For so long you have never thought of yourself making mistakes and examining why you made mistakes, and even refused to confess these mistakes.",
  "Bowing humbly is to bring your heart back to Dao. Cultivating Dao is to follow temple etiquette or regulations and the main purpose is to be modest and humble.",
  "There is no such thing as “retirement” from Dao propagation. Whatever you do must be clear, and decisive. Do not be pretentious and superficial. Do not be relaxed in learning or propagating Dao.",
  "The worse the adversity, the more you have got to undergo trails and tests. Adversity is the first step leading to Truth. Break through your blockage; your Way will be open and clear.",
  "Do not redirect your anger by venting it on somebody who is no to blame. Do not put wrong thoughts into action or repeat a previous mistake. Do not spread your bad temper and hence affect others in an emotional way.",
  "Buddhas are merciful; they are never willing to see people suffering. Understand your life, cherish your affinity, stay in good shape. So many people are awaiting you to bring Tao to them.",
  "Bring people to receive Tao. To change your current situation, you need to learn Tao and cultivate Tao. Heaven wants to see your true heart and will arrange good affinities for you.",
  "Understand the meaning of your life and start from making the best of your life. When you release yourself, willing to play your part and shoulder the mission given by God, true happiness will follow.",
  "People in higher positions must set good examples for others to follow. This is a good way to discover our true selves and improve ourselves accordingly.",
  "Bring people to receive Tao. To change your current situation, you need to learn Tao and cultivate Tao. Heaven wants to see your true heart and will arrange good affinities for you.",
  "Buddhas are merciful; they are never willing to see people suffering. Understand your life, cherish your affinity, stay in good shape. So many people are awaiting you to bring Tao to them.",
  "Be your own master; don’t be controlled by the external surrounding. Retain a heart of the obliged and react gratefully to those who treated you badly.",
  "If you accept your incorrectness that has indicated by the rest, you meanwhile make a huge progress.",
  "Do not wait for the opportunities to arrive; you can cultivate Dao and go straight back to The Heavenly World if only you allow yourself to do so.",
  "Examine what you have done and thought; do not be disturbed by the outside environment or be defeated by your own mind.",
  "You can improve yourself once you get rid of all bad habits and tempers. If you are unwilling to change your negative side, you won’t feel comfortable to be noticed by others, you will certainly be dragged downward.",
  "The clock doesn’t make sound for time without chiming; the drum doesn’t produce music without beating it. Your virtue is like a chiming clock and beating drum to awaken others.",
  "During the journey of cultivation, one’s mind can become as wide as the ocean by treating all others sincerely, striving to live peacefully, and with enough tolerance to forgive so that one can embrace all sentient beings.",
  "Free oneself from the obstacle of ignorance to freely fly through the blueprint of one’s life, manifest the kindheartedness and righteousness, stay on longer on the unsure side and refuse to be contaminated by bad influence.",
  "There’s always a light in the end of a tunnel, thus, be optimistically magnanimous and move forward courageously."
];

export {
  options,
  adult,
  kids,
  english
};